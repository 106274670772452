<template>
  <div>
    <Head></Head>
    <div
      class="indexpage pagetwo-page publicpage u-flex u-col-top u-row-between"
    >
      <div class="page-left">
        <yulan></yulan>
        <Dengxiao></Dengxiao>
        <div class="public-panel panelh3 u-m-t-10">
          <h2>电站列表</h2>
          <table class="table1">
            <colgroup>
              <col width="25%" />
              <col width="25%" />
              <col width="25%" />
              <col width="25%" />
            </colgroup>
            <tr>
              <th>名称</th>
              <th>功率</th>
              <th>今日发电</th>
              <th>累计发电</th>
            </tr>
          </table>
          <div class="table-body">
            <vue-seamless-scroll :data="siteList" :class-option="defaultOption">
              <table class="table1">
                <colgroup>
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                  <col width="25%" />
                </colgroup>
                <tr v-for="(item, index) in siteList" :key="index">
                  <td>
                    <i v-if="item.state === 1" class="online_backgroundColor" />
                    <i
                      v-if="item.state === 2"
                      class="offline_backgroundColor"
                    />
                    <i v-if="item.state === 3" class="fault_backgroundColor" />
                    <el-tooltip :content="item.name" placement="bottom">
                      <div>{{ item.name }}</div>
                    </el-tooltip>
                  </td>
                  <td class="cyan">
                    {{ item.power.value }}{{ item.power.unit }}
                  </td>
                  <td>{{ item.day_energy.value }}{{ item.day_energy.unit }}</td>
                  <td class="yellow">
                    {{ item.all_energy.value }}{{ item.all_energy.unit }}
                  </td>
                </tr>
              </table>
            </vue-seamless-scroll>
          </div>
        </div>
      </div>
      <div class="page-middle">
        <div class="mdtop u-rela">
          <!--        <all-map @changeMap="changeMap"></all-map>-->

          <baidu @change="openDetail"></baidu>
        </div>
        <div class="public-panel panelh4 u-flex u-row-around">
          <div
            class="item u-flex"
            v-for="(item, index) in numList"
            @click="openStation(index)"
            style="cursor: pointer"
            :key="index"
          >
            <div class="left u-flex u-row-center">
              <i
                :class="'icon' + index"
                class="animate__animated animate__pulse animate__infinite"
              ></i>
            </div>
            <div class="right u-flex u-flex-col u-row-center">
              <span class="u-font-26">{{ item.name }}</span>
              <span class="oswald u-font-40 blue">{{ item.num }}</span>
            </div>
          </div>
        </div>
        <div class="public-panel panelh5 u-m-t-10">
          <h2>今日实时功率</h2>
          <div class="chart" id="line1"></div>
        </div>
      </div>

      <div class="page-right">
        <month-report></month-report>
        <week-report></week-report>
        <div class="public-panel panelh3 u-m-t-10">
          <h2>任务统计</h2>
          <div class="chart" id="radar1"></div>
        </div>
      </div>
      <!-- 视频弹窗 -->
      <el-dialog
        class="custom-dialog index-dialog"
        :visible.sync="videoDialogVisible"
      >
        <template v-slot:title>
          <h3>视频</h3>
        </template>
        <div class="u-flex u-col-top">
          <div class="left u-flex-1 u-p-r-62">
            <div class="search">
              <el-input v-model="search" placeholder="请输入内容">
                <i slot="suffix" class="icon-search"></i>
              </el-input>
            </div>
            <el-tree
              class="u-m-t-26"
              :data="data"
              :props="defaultProps"
            ></el-tree>
          </div>
          <div class="videobox">
            <video src="~@/assets/images/video.mp4" controls></video>
          </div>
        </div>
        <div class="off" @click="videoDialogVisible = false"></div>
      </el-dialog>
      <!-- 配电图弹窗 -->
      <el-dialog
        class="custom-dialog index-dialog"
        :visible.sync="distributionDialogVisible"
      >
        <template v-slot:title>
          <h3>配电图</h3>
        </template>
        <img class="img1" src="~@/assets/images/dialog-img.png" alt="" />
        <div class="off" @click="distributionDialogVisible = false"></div>
      </el-dialog>
      <stationList @change="openDetail" ref="stationList"></stationList>
      <detail ref="detail"></detail>
    </div>
  </div>
</template>

<script>
import detail from "./compontents/detail";
import * as echarts from "echarts";
import yulan from "./compontents/yulan";
import MonthReport from "./compontents/MonthReport";
import { line2Option, getRadar } from "@/assets/js/charts";
import { getBigDataAlarms, getBigDataTasks } from "@/api/screen";
import WeekReport from "./compontents/WeekReport";
import {
  stationListTotal,
  getEnergyList,
  allstationReport,
} from "@/api/guangfu";
import { color, hex2rgb } from "@/unit/common-params";
import vueSeamlessScroll from "vue-seamless-scroll";
import tools from "@/unit/autotips";
import screenfull from "screenfull";
import Baidu from "@/views/guangfu1/compontents/baiduMap";
//import AllMap from "@/views/guangfu/compontents/ChinaMapCard";
import Dengxiao from "@/views/guangfu/compontents/Dengxiao";
import stationList from "@/views/guangfu/compontents/stationList";
import Head from "@/views/guangfu1/compontents/Head";
export default {
  name: "",
  data() {
    return {
      showStationList: true,
      project_id: 1, //项目id
      search: "", //搜索
      is_detail: false,
      videoDialogVisible: false, //视频弹窗
      distributionDialogVisible: false, //配电图弹窗
      paramsColor: color,
      data: [
        {
          //树状结构
          label: "老厂房",
          children: [
            {
              label: "系统研发部",
              children: [
                {
                  label: "新仓房",
                },
                {
                  label: "长沙先导",
                },
              ],
            },
          ],
        },
      ],
      tool: {},
      defaultProps: {
        children: "children",
        label: "label",
      },

      load: {
        cpu: 0,
        memory: 0,
        disk: 0,
      },
      siteList: [],
      alarmList: [
        {
          THETIME: "2022-02-23 14:15:36",
          stationName: "新厂区",
          OBJDESC: "新厂房节点服务器通讯",
          DESCRIPTION: "通讯连通",
          NAME: "一般",
        },
      ],
      numList: [
        {
          name: "站点个数",
          num: 5,
        },
        {
          name: "设备总数",
          num: 154,
        },
        {
          name: "测点总数",
          num: 2653,
        },
        {
          name: "管理人员",
          num: 55,
        },
        {
          name: "运维工程师",
          num: 401,
        },
      ],
    };
  },
  components: {
    Head,
    Dengxiao,
    Baidu,
    WeekReport,
    yulan,
    stationList,
    vueSeamlessScroll,
    MonthReport,
    detail,
  },
  computed: {
    defaultOption() {
      return {
        step: 0.2, // 数值越大速度滚动越快
        limitMoveNum: 2, // 开始无缝滚动的数据量 this.dataList.length
        hoverStop: true, // 是否开启鼠标悬停stop
        direction: 1, // 0向下 1向上 2向左 3向右
        openWatch: true, // 开启数据实时监控刷新dom
        singleHeight: 0, // 单步运动停止的高度(默认值0是无缝不停止的滚动) direction => 0/1
        singleWidth: 0, // 单步运动停止的宽度(默认值0是无缝不停止的滚动) direction => 2/3
        waitTime: 1000, // 单步运动停止的时间(默认值1000ms)
      };
    },
  },
  created() {
    console.log("fullPath", document.fullscreenEnabled);
  },

  mounted() {
    screenfull
      .toggle()
      .then((res) => {
        console.log("全屏", res);
      })
      .catch((e) => {
        console.log("全屏", e);
      });
    setInterval(() => {
      this.getEnergyList();
      this.stationListTotal();
      this.getBigDataLoad();
      this.getBigDataTasks();
    }, 300000);
    setInterval(() => {
      window.location.reload();
    }, 3600000);
    this.getEnergyList();
    this.stationListTotal();
    this.getBigDataLoad();
    this.getBigDataTasks();
    //表格颜色
    this.onGrade();
  },
  methods: {
    openDetail(id) {
      const detail = this.$refs.detail;
      console.log("id", id);
      detail.open(id);
    },
    openStation(index) {
      const stationList = this.$refs.stationList;
      stationList.state = index;
      stationList.distrListDialogVisible = true;
      stationList.getEnergyList();
    },
    changeMap(id) {
      console.log(id);
    },
    getBigDataTasks() {
      getBigDataTasks({ project_id: this.project_id }).then((res) => {
        const data = [{ value: [] }];
        res.res.map((item) => {
          data[0].value.push(item.value);
        });
        console.log("task", data);
        this.$nextTick(() => {
          getRadar("radar1", data);
        });
      });
    },

    getBigDataAlarms() {
      getBigDataAlarms({ project_id: this.project_id }).then((res) => {
        this.alarmList = res.res;
      });
    },
    getEnergyList() {
      getEnergyList({ page_size: 300 }).then((res) => {
        this.siteList = res.data;
      });
    },
    getDpr(num) {
      let width = window.screen.width;
      if (width > 1920) {
        return num;
      } else {
        return num / 2;
      }
    },
    getBigDataLoad() {
      allstationReport({ project_id: this.project_id }).then((res) => {
        const data = res.data.data;
        const series = [];
        data.map((item, index) => {
          series.push({
            name: item.name,
            type: "line",
            data: item.data,
            showSymbol: false,
            smooth: true,
            lineStyle: {
              color: this.paramsColor[index],
              width: this.getDpr(2),
            },
            itemStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 1,
                y2: 0,
                colorStops: [
                  {
                    offset: 0,
                    color: this.paramsColor[index], // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: hex2rgb(this.paramsColor[index], 0), // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
            areaStyle: {
              color: {
                type: "linear",
                x: 0,
                y: 0,
                x2: 0,
                y2: 1,
                colorStops: [
                  {
                    offset: 0,
                    color: hex2rgb(this.paramsColor[index], 0.5), // 0% 处的颜色
                  },
                  {
                    offset: 1,
                    color: this.paramsColor[index], // 100% 处的颜色
                  },
                ],
                global: false, // 缺省为 false
              },
            },
          });
        });
        this.$nextTick(() => {
          let myChart = echarts.init(document.getElementById("line1"));
          line2Option.legend.title = res.data.titles;
          line2Option.series = series;
          myChart.setOption(line2Option);
          if (this.tool["line1"] !== undefined) {
            console.log("clearInterval", "消除");
            this.tool["line1"].clearLoop();
          }
          const tool = tools.loopShowTooltip(myChart, line2Option, {
            loopSeries: true,
          });
          this.tool["line1"] = tool;
        });
      });
    },

    stationListTotal() {
      stationListTotal({ project_id: this.project_id }).then((res) => {
        this.numList = res.data;
      });
    },

    //饼图占有率控制颜色
    onBj(num) {
      if (num < 40) {
        return "bg1";
      } else if (num >= 40 && num < 60) {
        return "bg2";
      } else {
        return "bg1";
      }
    },
    //饼图背景色控制
    onPieBJ(num) {
      if (num < 40) {
        return "bgpie1";
      } else if (num >= 40 && num < 60) {
        return "bgpie2";
      } else {
        return "bgpie1";
      }
    },
    //饼图数值控制显示颜色
    getIfpie(num) {
      if (num < 40) {
        return ["rgba(0, 156, 255, 1)", "rgba(0, 156, 255, 0)"];
      } else if (num >= 40 && num < 60) {
        return ["rgba(244, 223, 105, 1)", "rgba(244, 223, 105, 0)"];
      } else {
        return ["rgba(0, 156, 255, 1)", "rgba(0, 156, 255, 0)"];
        //   return ["rgba(255, 51, 119, 1)", "rgba(255, 51, 119, 0)"];
      }
    },
    beforeCreate() {
      document.title = this.$store.state.screen.title;
    },
    onGrade(name) {
      if (name == "可疑") {
        return "yellow1";
      } else if (name == "一般") {
        return "orange";
      } else if (name == "严重") {
        return "purple";
      } else if (name == "致命") {
        return "red";
      } else if (name == "操作") {
        return "violet";
      } else if (name == "正常") {
        return "orange1";
      }
    },
  },
};
</script>
<style scoped>
/deep/ .publicpage .table1 td {
  font-size: 0.3rem;
}

.offline_backgroundColor {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  float: left;
  background-color: #ccc !important;
}
.online_backgroundColor {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  float: left;
  background: #14dbff;
}

.fault_backgroundColor {
  display: block;
  width: 5px;
  height: 5px;
  border-radius: 50%;
  float: left;
  background-color: #d45559 !important;
}
</style>
