<template>
  <div class="pagetwo-page">
    <div class="public-panel panelh1 distribution">
      <h2 class="u-flex u-row-between">
        <span>电站概况</span>
      </h2>

      <div class="u-flex u-flex-wrap u-p-l-50 u-m-t-20">
        <div class="item u-flex">
          <i class="icon icon3"></i>
          <div class="u-flex u-flex-col u-col-top u-font-26">
            <span>今日发电</span>
            <span
              ><i class="oswald u-font-50 blue">{{ detail.day_energy.value }}</i
              ><i>{{ detail.day_energy.unit }}</i></span
            >
          </div>
        </div>
        <div class="item u-flex">
          <i class="icon icon6"></i>
          <div class="u-flex u-flex-col u-col-top u-font-26">
            <span>今日收益</span>
            <span
              ><i class="oswald u-font-50 blue">{{
                detail.day_in_come.value
              }}</i
              ><i>{{ detail.day_in_come.unit }}</i></span
            >
          </div>
        </div>

        <div class="item u-flex">
          <i class="icon icon3"></i>
          <div class="u-flex u-flex-col u-col-top u-font-26">
            <span>累计发电</span>
            <span
              ><i class="oswald u-font-50 blue">{{ detail.all_energy.value }}</i
              ><i>{{ detail.all_energy.unit }}</i></span
            >
          </div>
        </div>
        <div class="item u-flex">
          <i class="icon icon6"></i>
          <div class="u-flex u-flex-col u-col-top u-font-26">
            <span>累计收益</span>
            <span
              ><i class="oswald u-font-50 blue">{{
                detail.all_in_come.value
              }}</i
              ><i>{{ detail.all_in_come.unit }}</i></span
            >
          </div>
        </div>

        <div class="item u-flex">
          <i class="icon icon4"></i>
          <div class="u-flex u-flex-col u-col-top u-font-26">
            <span>实时功率</span>
            <span
              ><i class="oswald u-font-50 blue">{{ detail.power.value }}</i
              ><i>{{ detail.power.unit }}</i></span
            >
          </div>
        </div>
        <div class="item u-flex">
          <i class="icon icon5"></i>
          <div class="u-flex u-flex-col u-col-top u-font-26">
            <span>组件容量</span>
            <span
              ><i class="oswald u-font-50 blue">{{ detail.capacity.value }}</i
              ><i>{{ detail.capacity.unit }}</i></span
            >
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { getGailan } from "@/api/guangfu";
export default {
  name: "yulan",
  data() {
    return {
      detail: {},
      insterval: this.$store.state.screen.time,
      timer: null,
    };
  },
  created() {
    this.getGailan();
    this.timer = setInterval(() => {
      this.getGailan();
    }, 60000);
  },
  methods: {
    getGailan() {
      getGailan({ service_id: this.server_id }).then((res) => {
        this.detail = res.data;
      });
    },
  },
};
</script>

<style scoped></style>
